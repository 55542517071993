import { httpService } from 'core-utilities';
import apiConstants from '../constants/apiEndpoint';

const blockUser = async (profileId: number): Promise<{}> => {
  const { data }: { data: {} } = await httpService.post(apiConstants.blockUserV2(profileId));
  return data;
};

const unblockUser = async (profileId: number): Promise<{}> => {
  const { data }: { data: {} } = await httpService.post(apiConstants.unblockUserV2(profileId));
  return data;
};

const isBlockedUser = async (profileId: number): Promise<boolean> => {
  const { data }: { data: boolean } = await httpService.get(apiConstants.isBlockedUser(profileId));
  return data;
};

export default {
  blockUser,
  unblockUser,
  isBlockedUser
};
