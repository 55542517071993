import { EventStream } from 'Roblox';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { BadgeSizes, VerifiedBadgeIconContainer } from 'roblox-badges';
import { createModal, Tooltip } from 'react-style-guide';
import { UserProfileField, writeQuery } from 'roblox-user-profiles';
import Constants from '../constants/profileHeaderConstants';
import contactsService from '../services/contactsService';
import friendStatusEnums from '../enums/FriendStatus';

import useProfileHeaderContext from '../hooks/useProfileHeaderContext';

const [EditAliasModal, modalService] = createModal();

const ProfileNames = ({
  profileUserId,
  translate
}: {
  profileUserId: number;
  translate: (key: string) => string;
}): JSX.Element => {
  const { state } = useProfileHeaderContext();
  let premiumIcon;
  let verifiedBadge;
  if (state.hasVerifiedBadge) {
    verifiedBadge = (
      <VerifiedBadgeIconContainer
        overrideImgClass='profile-verified-badge-icon'
        size={BadgeSizes.TITLE}
        titleText={profileUserId.toString()}
      />
    );
  } else if (state.hasPremiumMembership) {
    premiumIcon = (
      <span title='premium icon' className='premium-badge-right-aligned'>
        <span className='icon-premium-medium' />
        <span className='icon-premium-small' />
      </span>
    );
  }

  const [currentName, setCurrentName] = useState<string>('');
  const [textCount, setTextCount] = useState<number>(0);
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const fireCustomNameModalOpened = () => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.modalOpen,
      Constants.eventCtx.customizeName,
      {
        origin: 'userProfile',
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameModalClosed = () => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.buttonClick,
      Constants.eventCtx.customizeName,
      {
        btn: Constants.eventBtns.closeCustomName,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameSaved = (alias: string) => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.buttonClick,
      Constants.eventCtx.customizeName,
      {
        btn: Constants.eventBtns.saveAlias,
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameSaveFailed = (alias: string) => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.customNameInvalidInput,
      Constants.eventCtx.customizeName,
      {
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireMaxLimitAlias = (alias: string) => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.customNameMaxLimit,
      Constants.eventCtx.customizeName,
      {
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireClearedAlias = () => {
    EventStream.SendEventWithTarget(
      Constants.eventNames.customNameClearedInput,
      Constants.eventCtx.customizeName,
      {
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  // This method fixes issues with emojis counting as 2 characters
  // Note: There is a known issue with niche complex emojis such as 👩‍❤️‍💋‍👩
  // where they will still be counted as multiple characters. The solution
  // to that would require a third-party library as native JavaScript does
  // not have a built-in way to handle this.
  const getUnicodeTextLength = (alias: string) => {
    return Array.from(alias).length;
  };

  const resetAliasModal = () => {
    setCurrentName(state.names.alias || '');
    setTextCount(getUnicodeTextLength(state.names.alias || ''));
    setHasErrored(false);
  };

  useEffect(() => {
    resetAliasModal();
  }, [state]);

  const openAliasModal = () => {
    modalService.open();
    fireCustomNameModalOpened();
  };

  const closeAliasModal = () => {
    resetAliasModal();
    modalService.close();
    fireCustomNameModalClosed();
    setTimeout(() => {
      (document.activeElement as HTMLElement).blur();
    });
  };
  const userProfileFields = [
    UserProfileField.Names.CombinedName,
    UserProfileField.Names.Username,
    UserProfileField.Names.DisplayName,
    UserProfileField.Names.Alias
  ];

  const setAlias = (alias: string): void => {
    contactsService.setUserTag(profileUserId, alias).then(
      response => {
        if (response.status === 'Success') {
          if (alias === '') {
            writeQuery(profileUserId, userProfileFields, {
              names: {
                alias,
                combinedName: state.names.displayName,
                displayName: state.names.displayName,
                username: state.names.username
              }
            });
          } else {
            writeQuery(profileUserId, userProfileFields, {
              names: {
                alias,
                combinedName: alias,
                displayName: state.names.displayName,
                username: state.names.username
              }
            });
          }
          fireCustomNameSaved(alias);
          closeAliasModal();
        } else {
          fireCustomNameSaveFailed(alias);
          setHasErrored(true);
        }
      },
      () => {
        setHasErrored(true);
      }
    );
  };

  const formatUsername = (username: string | null | undefined) => {
    return username ? `@${username}` : '';
  };

  const updateCurrentName = (e: React.FormEvent<HTMLInputElement>) => {
    let newName = e.currentTarget.value;
    let unicodeTextLength = getUnicodeTextLength(newName);
    if (unicodeTextLength > Constants.maxCharactersForAlias) {
      if (textCount === Constants.maxCharactersForAlias) {
        return;
      }

      newName = Array.from(newName).slice(0, Constants.maxCharactersForAlias).join('');
      unicodeTextLength = Constants.maxCharactersForAlias;
    }

    setCurrentName(newName);
    setTextCount(unicodeTextLength);

    if (unicodeTextLength === Constants.maxCharactersForAlias) {
      fireMaxLimitAlias(newName);
    }
    if (unicodeTextLength === 0) {
      fireClearedAlias();
    }
    setHasErrored(false);
  };

  const errorText = hasErrored ? translate(Constants.translationKeys.invalidAlias) : '';
  const countdownText = `${textCount}/${Constants.maxCharactersForAlias}`;
  const descriptionHeader = `${translate(Constants.translationKeys.setCustomName)} `;
  const descriptionText = translate(Constants.translationKeys.recognizeFriends);
  const placeHolderText = translate(Constants.translationKeys.customizeNamePlaceholder);
  const modalTitle = translate(Constants.translationKeys.customizeName);

  const inputClassNames = classNames(`form-group`, {
    'form-has-error': hasErrored,
    'form-has-feedback': true
  });

  const editStyle: React.CSSProperties = {
    backgroundPosition: state.names.alias && state.names.alias !== '' ? '-20px -60px' : '-0px -60px'
  };

  const tooltipHeader = translate(Constants.translationKeys.customName);
  const tooltipBody = translate(Constants.translationKeys.recognizeTooltip);

  const setAliasButton =
    state.friendStatus === friendStatusEnums.Friends ? (
      <Tooltip
        id='mutual-friends-tooltip'
        placement='bottom'
        content={
          <div>
            <h1 className='name-tooltip-header text-overflow text-label'>{tooltipHeader}</h1>
            <div className='name-tooltip-body multiple-lines text-tooltips'>{tooltipBody}</div>
          </div>
        }>
        <span className='change-alias-button'>
          <button className='btn-generic-edit-sm' type='button' onClick={openAliasModal}>
            <span className='icon-edit' style={editStyle} />
          </button>
        </span>
      </Tooltip>
    ) : null;

  const profileNameButton =
    state.friendStatus === friendStatusEnums.Friends ? (
      <button
        type='button'
        className='profile-name-button'
        onClick={openAliasModal}
        disabled={state.friendStatus !== friendStatusEnums.Friends}>
        <h1 className='profile-name text-overflow'>{state.names.combinedName}</h1>
        <h1 className='profile-name text-overflow font-header-1'>{state.names.combinedName}</h1>
      </button>
    ) : (
      <React.Fragment>
        <h1 className='profile-name text-overflow'>{state.names.combinedName}</h1>
        <h1 className='profile-name text-overflow font-header-1'>{state.names.combinedName}</h1>
      </React.Fragment>
    );

  return (
    <div className='header-names'>
      <div className='header-title'>
        {setAliasButton}
        {profileNameButton}
        {verifiedBadge}
        {premiumIcon}
      </div>
      <div className='profile-display-name font-caption-body text text-overflow'>
        {formatUsername(state.names.username)}
      </div>
      <EditAliasModal
        title={modalTitle}
        body={
          <div className='change-alias-modal'>
            <div className='text-label'>
              <span>{descriptionHeader}</span>
              <span className='change-alias-bolded'>{state.names.displayName}</span>
            </div>
            <div className='change-alias-description'>{descriptionText}</div>
            <div className={inputClassNames}>
              <input
                className='form-control input-field'
                id='aliasInputBox'
                onChange={updateCurrentName}
                value={currentName}
                placeholder={placeHolderText}
              />
              <div className='clearfix font-caption-body change-alias-feedback-container'>
                <span className='count-down'>{countdownText}</span>
                <span className='form-control-label'>{errorText}</span>
              </div>
            </div>
          </div>
        }
        actionButtonShow
        disableActionButton={hasErrored}
        actionButtonText={translate(Constants.translationKeys.save)}
        neutralButtonText={translate(Constants.translationKeys.cancel)}
        onNeutral={() => {
          closeAliasModal();
        }}
        onAction={() => {
          setAlias(currentName);
        }}
      />
    </div>
  );
};

export default ProfileNames;
