import { httpService } from 'core-utilities';
import apiConstants from '../constants/apiEndpoint';
import { TGetWebProfileUIPolicyResponse } from '../types/profileHeaderTypes';

const getPolicies = async (): Promise<TGetWebProfileUIPolicyResponse> => {
  const { data }: { data: TGetWebProfileUIPolicyResponse } = await httpService.get(
    apiConstants.webProfileUIPolicy()
  );
  return data;
};

export default {
  getPolicies
};
