import React from 'react';
import { Endpoints, EventStream } from 'Roblox';
import { authenticatedUser } from 'header-scripts';
import ProfileDropdown from '../components/ProfileDropdown';
import profileHeaderConstants from '../constants/profileHeaderConstants';
import authService from '../services/authService';
import friendsService from '../services/friendsService';
import useProfileHeaderContext from '../hooks/useProfileHeaderContext';
import { ActionType } from '../store/action';

const ProfileDropdownContainer = ({
  profileUserId,
  sendMessage,
  translate
}: {
  profileUserId: number;
  sendMessage: () => void;
  translate: (key: string) => string;
}): JSX.Element => {
  const { state, dispatch } = useProfileHeaderContext();
  const { id } = authenticatedUser;
  const isMyProfile = profileUserId === id;
  const isAuthenticated = id !== -1;
  const loginUrl = Endpoints.getAbsoluteUrl('/login');
  const inventoryUrl = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/inventory/`);
  const favoritesUrl = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/favorites#!/places`);
  const homeUrl = Endpoints.getAbsoluteUrl('/home');
  // no api exists for this currently, still have to pull from the data attributes for now
  const mayImpersonate =
    document.querySelector('div[data-mayimpersonate]')?.getAttribute('data-mayimpersonate') ===
    'true';

  const sendFollow = async () => {
    try {
      const follow = await friendsService.followUser(profileUserId);
      if (follow.success) {
        dispatch({ type: ActionType.UPDATE_FOLLOWERS_COUNT, amount: 1 });
        dispatch({ type: ActionType.UPDATE_FOLLOWING, following: true });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message: translate(profileHeaderConstants.translationKeys.error.followFailed)
      });
    }
  };

  const unFollow = async () => {
    if (!id) {
      window.location.href = loginUrl;
    } else {
      try {
        await friendsService.unFollowUser(profileUserId);
        dispatch({ type: ActionType.UPDATE_FOLLOWERS_COUNT, amount: -1 });
        dispatch({ type: ActionType.UPDATE_FOLLOWING, following: false });
      } catch (error) {
        dispatch({
          type: ActionType.SET_ERROR_MESSAGE,
          message: translate(profileHeaderConstants.translationKeys.error.unfollowFailed)
        });
      }
    }
  };

  const impersonateUser = async () => {
    try {
      await authService.impersonateUser(profileUserId);
      window.location.href = homeUrl;
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message: translate(profileHeaderConstants.translationKeys.error.impersonateUserFailed)
      });
    }
  };

  const tradeItems = () => {
    EventStream.SendEventWithTarget(
      profileHeaderConstants.eventNames.tradeEntryPoint,
      profileHeaderConstants.eventCtx.profileMenu,
      {
        partnerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
    window.location.href = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/trade`);
  };

  const buttons = [
    {
      label: profileHeaderConstants.translationKeys.dropdown.follow,
      visible: !isMyProfile && !state.isFollowing && !state.isBlocked,
      onClick: sendFollow
    },
    {
      label: profileHeaderConstants.translationKeys.dropdown.unfollow,
      visible: !isMyProfile && state.isFollowing && !state.isBlocked,
      onClick: unFollow
    },
    {
      label: profileHeaderConstants.translationKeys.dropdown.tradeItems,
      visible: !state.isBlocked && state.canTradeWith,
      onClick: tradeItems
    },
    {
      label: !state.isBlocked
        ? profileHeaderConstants.translationKeys.dropdown.blockUser
        : profileHeaderConstants.translationKeys.dropdown.unblockUser,
      visible: !isMyProfile && isAuthenticated,
      onClick: () => {
        dispatch({ type: ActionType.SHOW_BLOCK_USER_MODAL, show: true });
      }
    },
    {
      label: profileHeaderConstants.translationKeys.dropdown.inventory,
      visible: profileUserId !== 1,
      onClick: () => {
        window.location.href = inventoryUrl;
      }
    },
    {
      label: profileHeaderConstants.translationKeys.dropdown.favorites,
      visible: state.hasFavorites,
      onClick: () => {
        window.location.href = favoritesUrl;
      }
    },
    {
      label: profileHeaderConstants.translationKeys.dropdown.impersonateUser,
      visible: mayImpersonate,
      onClick: impersonateUser
    }
  ];
  return <ProfileDropdown translate={translate} buttons={buttons} />;
};

export default ProfileDropdownContainer;
