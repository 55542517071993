import React from 'react';
import { urlService } from 'core-utilities';
import FriendStatus from '../enums/FriendStatus';
import profileHeaderConstants from '../constants/profileHeaderConstants';
import useExperiments from '../hooks/useExperiments';
import useProfileHeaderContext from '../hooks/useProfileHeaderContext';

const SocialButtons = ({
  profileUserId,
  isMyProfile,
  canBeFollowed,
  translate,
  sendFriendRequest,
  acceptFriendRequest,
  removeFriend,
  sendMessage,
  startChat,
  launchGame,
  keyEvent
}: {
  profileUserId: number;
  isMyProfile: boolean;
  canBeFollowed: boolean;
  sendFriendRequest: () => {};
  acceptFriendRequest: () => {};
  removeFriend: () => {};
  sendMessage: () => void;
  startChat: () => void;
  launchGame: () => void;
  translate: (key: string) => string;
  keyEvent: (event: React.KeyboardEvent, request: () => {} | void) => void;
}): JSX.Element => {
  const { state } = useProfileHeaderContext();
  const friendsUrl = urlService.getAbsoluteUrl(`/users/${profileUserId}/friends#!`);

  const experiments = useExperiments(
    profileHeaderConstants.experiments.profileFRAcceptCTALayer,
    state.friendStatus === FriendStatus.RequestReceived
  );
  const isEligibleForProfileAcceptCTAExperiment =
    experiments.data?.[profileHeaderConstants.experiments.profileFRAcceptCTAParameter];

  let desktopFriendButton = null;
  let mobileFriendButton = null;

  if (!isMyProfile) {
    if (state.friendStatus === FriendStatus.RequestReceived) {
      desktopFriendButton = (
        <button
          type='button'
          className={isEligibleForProfileAcceptCTAExperiment ? 'btn-primary-md' : 'btn-control-md'}
          data-target-user-id={profileUserId}
          data-friends-url={friendsUrl}
          onClick={acceptFriendRequest}>
          {translate(profileHeaderConstants.translationKeys.buttons.accept)}
        </button>
      );
      mobileFriendButton = (
        <div
          role='link'
          tabIndex={0}
          data-target-user-id={profileUserId}
          data-friends-url={friendsUrl}
          className='action-add-friend'
          onClick={acceptFriendRequest}
          onKeyDown={(event: React.KeyboardEvent) => keyEvent(event, acceptFriendRequest)}>
          <div className='icon-accept-friend' />
          <div className='text-label small'>
            {translate(profileHeaderConstants.translationKeys.buttons.accept)}
          </div>
        </div>
      );
    }
    if (state.friendStatus === FriendStatus.NotFriends) {
      desktopFriendButton = (
        <button
          type='button'
          className={`btn-control-md ${state.isBlocked ? 'disabled' : ''}`}
          onClick={sendFriendRequest}>
          {translate(profileHeaderConstants.translationKeys.buttons.addFriend)}
        </button>
      );

      mobileFriendButton = (
        <div
          role='link'
          tabIndex={0}
          className='action-add-friend'
          onClick={sendFriendRequest}
          onKeyDown={(event: React.KeyboardEvent) => keyEvent(event, sendFriendRequest)}>
          <div className='icon-add-friend' />
          <div className='text-label small'>
            {translate(profileHeaderConstants.translationKeys.buttons.addFriend)}
          </div>
        </div>
      );
    }
    if (state.friendStatus === FriendStatus.RequestSent) {
      desktopFriendButton = (
        <button type='button' className='btn-control-md disabled'>
          {translate(profileHeaderConstants.translationKeys.buttons.pending)}
        </button>
      );

      mobileFriendButton = (
        <div role='link' tabIndex={0} className='action-friend-pending'>
          <div className='icon-friend-pending' />
          <div className='text-label small'>
            {translate(profileHeaderConstants.translationKeys.buttons.pending)}
          </div>
        </div>
      );
    }
    if (state.friendStatus === FriendStatus.Friends) {
      desktopFriendButton = (
        <button
          type='button'
          className='btn-alert-md'
          id='unfriend-btn'
          data-target-user-id={profileUserId}
          onClick={removeFriend}>
          {translate(profileHeaderConstants.translationKeys.buttons.unFriend)}
        </button>
      );

      mobileFriendButton = (
        <div
          role='link'
          tabIndex={0}
          data-target-user-id={profileUserId}
          onClick={removeFriend}
          onKeyDown={(event: React.KeyboardEvent) => keyEvent(event, removeFriend)}
          className='action-remove-friend'>
          <div className='icon-remove-friend' />
          <div className='text-label small'>
            {translate(profileHeaderConstants.translationKeys.buttons.unFriend)}
          </div>
        </div>
      );
    }
  }

  let desktopMessageButton = null;
  let mobileMessageButton = null;

  if (!isMyProfile) {
    if (state.canChat && state.friendStatus === FriendStatus.Friends) {
      desktopMessageButton = (
        <li className='btn-messages' id='profile-chat-btn'>
          <button type='button' className='btn-primary-md' onClick={startChat}>
            {translate(profileHeaderConstants.translationKeys.buttons.chat)}
          </button>
        </li>
      );

      mobileMessageButton = (
        <li className='btn-messages center-icon' id='profile-chat-icon'>
          <div
            role='link'
            tabIndex={0}
            className='action-message-disabled'
            onClick={startChat}
            onKeyDown={(event: React.KeyboardEvent) => keyEvent(event, startChat)}>
            <div className='icon-start-chat' />
            <div className='text-label small'>
              {translate(profileHeaderConstants.translationKeys.buttons.chat)}
            </div>
          </div>
        </li>
      );
    }
  }

  let desktopGameButton = null;
  let mobileGameButton = null;

  if (canBeFollowed && state.friendStatus !== '') {
    desktopGameButton = (
      <li className='btn-join-game'>
        <button onClick={launchGame} type='button' className='profile-join-game btn-growth-md'>
          {translate(profileHeaderConstants.translationKeys.buttons.joinGame)}
        </button>
      </li>
    );

    mobileGameButton = (
      <li className='btn-join-game last-icon'>
        <div
          role='link'
          tabIndex={0}
          onClick={launchGame}
          onKeyDown={(event: React.KeyboardEvent) => keyEvent(event, launchGame)}
          className='profile-join-game action-join-game'>
          <div className='icon-join-game' />
          <div className='text-label small'>
            {translate(profileHeaderConstants.translationKeys.buttons.joinGame)}
          </div>
        </div>
      </li>
    );
  }

  // Wait until received experiment data to render buttons
  if (experiments.loading) {
    desktopFriendButton = null;
    desktopMessageButton = null;
    desktopGameButton = null;
    mobileFriendButton = null;
    mobileMessageButton = null;
    mobileGameButton = null;
  }

  return (
    <React.Fragment>
      <ul className='details-actions desktop-action'>
        <li className='btn-friends'>{desktopFriendButton}</li>
        {desktopMessageButton}
        {desktopGameButton}
      </ul>
      <ul className={`details-actions mobile-action ${canBeFollowed ? 'three-item-list' : ''}`}>
        <li className='btn-friends'>{mobileFriendButton}</li>
        {mobileMessageButton}
        {mobileGameButton}
      </ul>
    </React.Fragment>
  );
};

export default SocialButtons;
