import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import profileHeaderConstants from './constants/profileHeaderConstants';
import ProfileHeaderContainer from './containers/ProfileHeaderContainer';
import { ProfileHeaderContextProvider } from './store/contextProvider';

import '../../../css/profileHeader/profileHeader.scss';

ready(() => {
  if (profileHeaderConstants.profileHeaderContainer()) {
    render(
      <ProfileHeaderContextProvider>
        <ProfileHeaderContainer />
      </ProfileHeaderContextProvider>,
      profileHeaderConstants.profileHeaderContainer()
    );
  }
});
