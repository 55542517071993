import React from 'react';
import { Dropdown } from 'react-style-guide';

const ProfileDropdown = ({
  buttons,
  translate
}: {
  buttons: { label: string; visible: boolean; onClick: () => void }[];
  translate: (key: string) => string;
}): JSX.Element => {
  const dropdownItems = buttons.map(item => {
    if (item.visible) {
      return (
        <Dropdown.Item onSelect={item.onClick} key={0} active={false}>
          {translate(item.label)}
        </Dropdown.Item>
      );
    }
    return null;
  });

  return (
    <div id='profile-header-more rbx-menu-item' className='profile-header-more'>
      <Dropdown className='profile-dropdown btn-generic-more-sm' id='popover-link'>
        {dropdownItems}
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
