import React from 'react';
import { abbreviateNumber, urlService } from 'core-utilities';

const SocialCount = ({
  label,
  count,
  profileId,
  subPage
}: {
  label: string;
  count: number;
  profileId: number;
  subPage: string;
}): JSX.Element => {
  const getFriendsPageUrl = () => {
    const friendsUrl = `/users/${profileId}/friends#!/${subPage}`;
    return urlService.getAbsoluteUrl(friendsUrl);
  };

  return (
    <li>
      <div className='text-label font-caption-header'>{label}</div>
      <a className='text-name' href={getFriendsPageUrl()}>
        <span className='font-header-2' title={count?.toString()}>
          {abbreviateNumber.getAbbreviatedValue(count)}
        </span>
      </a>
    </li>
  );
};

export default SocialCount;
